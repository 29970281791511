(async function () {
  const url = Array.from(document.getElementsByTagName('script'))
    .map((item) => item.attributes.getNamedItem('src')?.value)
    .filter((item) => String(item).search(/smartbot-chat/g) !== -1);

  const isDev = process.env.NODE_ENV === 'development';
  const baseUrl = url[0] ? new URL(url[0]) : { origin: isDev ? 'http://localhost:3020/' : '/' };
  const envScriptUrl = `${baseUrl.origin}__ENV.js`;

  try {
    await import(/* webpackIgnore: true */ envScriptUrl);
  } catch (e) {
    console.error(e);
  }

  try {
    import('./app').then(() => {
      window.dispatchEvent(new Event('chatbotready', { bubbles: true, cancelable: true }));
    });
  } catch (e) {
    console.error(e);
  }
})();

export {};
